import React, { Fragment } from "react"
import Layout from "../../../components/layout/layout"
import directionsContent from "../../../components/hut-data/hut2/directions"
import hutFictionContent from "../../../components/hut-data/hut2/hutFiction"
import aboutContent from "../../../components/hut-data/hut2/about"
import HutPageLayout from "../../../components/hutPageLayout/hutPageLayout"
import HutMap from "../../../components/hutMap/hutMap"
import { AccordionContent } from "../../../components/accordion/accordion"
import { GeolocationContext } from "../../../AppState"
import hutTitle from "../../../components/hut-data/hut2/hut-title.svg"
import doodle from "../../../components/hut-data/hut2/hut-doodle.jpg"
import aboutImg from "../../../components/hut-data/hut2/about-img.jpg"

const accordionContent: AccordionContent[] = [
  {
    title: "Directions",
    inner: (
      <Fragment>
        <img src={doodle} className="width-full" alt="" />
        <div className="u-side-pad u-top-pad">{directionsContent}</div>
      </Fragment>
    ),
    backgroundColour: "#fae297",
  },
  {
    title: "Found it",
    backgroundColour: "#ffcc99",
  },
  {
    title: "Hut fiction",
    inner: hutFictionContent,
    backgroundColour: "#d0dce2",
    locked: true,
  },
  {
    title: "About",
    inner: (
      <Fragment>
        <img src={aboutImg} alt="" className="width-full" />
        {aboutContent}
      </Fragment>
    ),
  },
]

function Hut() {
  return (
    <Layout title="Whareroa Hut">
      <GeolocationContext.Consumer>
        {context => {
          const { foundHuts } = context
          return (
            <div className="map__info-container">
              <HutMap
                isHutPage
                height="320px"
                foundHuts={foundHuts}
                bounds={[
                  [174.97723172810424, -40.968849789031786],
                  [174.99086483500514, -40.97585581263052],
                ]}
              />
            </div>
          )
        }}
      </GeolocationContext.Consumer>
      <HutPageLayout
        hutId="hut2"
        heading="Whareroa Hut"
        headingImg={hutTitle}
        headingColour="#e53887"
        description="A hut to shelter from the wind. A hearth to light your way. Where will you go from here?"
        accordionContent={accordionContent}
        unlockCode="CL1MBER"
      />
    </Layout>
  )
}

export default Hut
