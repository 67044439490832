import React, { Fragment } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const directions = (
  <Fragment>
    <h3>Start Location</h3>
    <p>Whareroa Farm info bay, opposite Mackays Crossing</p>
    <h3>Distance to Hut</h3>
    <p>1.2km to 90m elevation</p>
    <h3>Your Directions</h3>
    <p>
      Visit the info bay and find the ‘Forest Loop Walk’ route to the Hub. Walk
      along sealed track, past toilets, until you reach a junction sign, turn
      towards ‘Coastal Lookout’, crossing the footbridge for the more scenic
      direction of this route (inclines through bush, 1km) or turn right to go
      through kiss gate to the ‘Farm Race’ (mostly flat along old farm road, no
      shade cover, 1km). You’ve reached the Hub when you see the caravan. From
      here, look south, up the hill towards the nikau palms and Matai Bush.
      That’s where you are heading. There are two ways up to the hut:
    </p>
    <p>
      Route #1: Follow the track uphill from the Hub along the grass path
      towards the farm gate. Climb over the style at the gate and continue to
      follow the grass path uphill. You’ll pass the Matai Tree sign, stop for a
      big tree hug and then keep climbing. Follow the track through the long
      grass until you reach the hut.
    </p>
    <p>
      Route #2: Go back a little way to where the farm race crossed the stream
      at the entrance to the Hub. Climb over the stile on your left and follow
      the Ti Kouka Loop track as it follows the western side of this stream
      uphill. At the top of this path, cross the small bridge and follow the
      grass path out to clearing. Look up and let the joy of being within view
      of the hut sweep through you. Head up through the long grass towards the
      hut.
    </p>
    <h3>Nearest Train Station</h3>
    <p>4km from Paekākāriki Station</p>
    <h3>Safety Notes</h3>
    <p>
      Latch back the doors when sitting in the hut. Whareroa is still a working
      farm so no dogs are permitted and there is a year-round fire ban.
    </p>
    <p>
      Allow 1-3 hours for a return trip, with rests. Consider making this hut
      journey a day trip, there are plenty of great picnic spots at the Hub and
      just below the hut. Steep inclines are unavoidable so take it easy. Bring
      a picnic or packed lunch and remember: Everywhere you go, always take your
      rubbish with you.
    </p>
    <p>
      While there are shaded route options being sun smart is recommended, the
      last leg to the hut is exposed through long grass (potential allergy and
      asthma risk). There are toilets at the Hub and by the Info Bay.
    </p>
    <h3>Travel Notes</h3>
    <p>
      This is a great location to bring your bike, bike the Farm Race and park
      at the Hub for a shorter walk to the hut. If you want to make a day of it
      there are plenty more walking and mountain biking tracks though the
      reserve,{" "}
      <OutboundLink
        href="https://drive.google.com/open?id=1Gokey9tbVS4RKnhzwiFAD0JznFMDvxHL"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download the Whareroa Farm map
      </OutboundLink>
      .
    </p>
    <p>
      <OutboundLink
        href="https://drive.google.com/open?id=1CE_f97hVCXE3tLQ-lAK3rvSqvrYPoaPh"
        target="_blank"
        rel="noopener noreferrer"
        className="big-link"
      >
        Download for offline use
      </OutboundLink>
    </p>
  </Fragment>
)

export default directions
