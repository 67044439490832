import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Audio from "../../../components/audio/audio"
import hutAudio from "./hut-audio.mp3"
import gif from "./fictionGif.mp4"

const hutFiction = (
  <>
    <video
      autoPlay
      loop
      muted
      playsInline
      src={gif}
      style={{ width: "100%" }}
    ></video>

    <div className="u-side-pad u-bottom-pad">
      <h3 className="heading--big">Regeneration</h3>
      <h4 className="heading--small">By Mandy Hager</h4>
      <p>
        A month after leaving hospital, Meg’s days are still plagued by pain. It
        steals all thought except a growing dread that invades her nights as
        well.
      </p>
      <p>
        Her dreams taunt her, always starting with a joyous glide in her kayak,
        trailing a hand through the silky sea, the sun casting silver jewels
        around her, the breeze a soft kiss on her cheeks. She feels connected to
        the planet, an amphibious creature, more at home on its fluid expanse
        than when she plants her feet on solid ground. It whispers to her; tells
        her this is where she belongs. Promises that if she loves it, it will
        love her back.
      </p>
      <p>
        But that’s when the dread elbows in, forcing her back to that terrible
        day. Out of nowhere, it seemed, the sun was swallowed whole, the rolling
        bank of cloud sneaking up behind her like a black-clad ninja, shocking
        the sea into waves as the wind struck with a thunderous clap.
      </p>
      <p>
        Only then did she realise how far she’d drifted south of the beach she’d
        launched from. She fought to turn the kayak head-on to the steepening
        chop, panic charging her muscles, yet the paddle was no match for an
        angry sea that rose to spit in her face.
      </p>
      <p>
        The sky pressed lower, hard pellets of rain pitting the water’s surface,
        drumming on the kayak, beating her. Wind funnelled between the sea and
        cloud, whipping the waves to frenzied peaks, thrashing the hull. She was
        driven backwards, up, down, sideways, towards the rocks that lurk near
        the highway past Pukerua Bay.
      </p>
      <p>
        With a roar like a demon unleashed, a violent gust scooped up a wave and
        swamped her, tipping the kayak over, pitching her head-down into the
        seething wash, her hair a smothering veil as she fought to break the
        surface, slapped and shoved, the kayak clipping her head, water pouring
        into her mouth, coughing, disorientated, battling for air, tossed around
        until Tangaroa’s hand picked her up and flung her onto the rocks. Her
        vertebrae snapped with a full-body pop before the fog descended.
      </p>
      <p>
        This is always when she wakes, gasping, heart thudding, her mother
        stooped over her, a cool hand on her brow.
      </p>
      <p>‘Shhh,’ she says to Meg. ‘I’m here.’</p>
      <p>
        But though Meg quietens as her mother plumps the pillows, her heart
        continues to race and that sense of dread lies heavy beside her on the
        bed. What if she never fully heals? What then? She can’t even imagine
        it. Doesn’t want to. Is far too scared.
      </p>
      <p>
        Now she half-listens to the radio to block such thoughts. Today’s physio
        session has left her drained. She was bullied to stretch, to build her
        core, fringe slicked to her forehead, breath panting out, legs shaking
        from the strain, hating the therapist, hating herself, hating her
        mother, who sat there with her optimistic smile pinned on, unaware how
        concern spews from her eyes.
      </p>
      <p>
        Last week her parents presented her with a flash new kayak to replace
        hers. A Kai Moana, fluorescent yellow, their carrot on a stick, watching
        her reaction like the starving watch someone eat. Her faked enthusiasm
        sucked all her energy.
      </p>
      <p>
        Jack, her big bro, is the only one who treats her normally. Ted refused
        a birthday party when he turned eleven last week, saying he felt too
        sad. He’s lost his bounce; is as watchful as a beaten puppy. His hurt is
        her hurt, and her hurt, his.
      </p>
      <p>
        She closes her eyes again, a woman on the news describing the scene
        after a bushfire demolished her home. ‘It feels like the end of the
        world,’ she says.
      </p>
      <p>It is, Meg thinks.</p>
      <p>
        She dozes, rocked awake by the clatter of Ted’s backpack as he shrugs it
        off. His face glows for the first time since her accident. ‘Wow. What’s
        up, kid?’
      </p>
      <p>
        He perches beside her and waits for Jack to settle in the chair. ‘I had
        this dream last night,’ he says. ‘I think it’s a primonition.’
      </p>
      <p>Her brow scrunches. ‘A what?’</p>
      <p>Jack laughs. ‘He means premonition.’</p>
      <p>
        Ted rolls his eyes at Meg. ‘Whatever.’ He takes up her hand. ‘Remember
        the tiny house we found that day at Whareroa Farm?’
      </p>
      <p>She nods, unsure where he’s going this. ‘That little hut?’</p>
      <p>
        ‘Yeah, remember?’ His eyes are a pure milk chocolate brown, while hers
        and Jack’s have tinges of amber and green. ‘In my dream we take you
        there and sit you inside — and something happens — something magic — and
        you’re cured, just like that.’ She flinches when he snaps his fingers.
        ‘Seriously, Meg. I have this really strong feeling if we take you there
        you’ll be okay.’
      </p>
      <p>
        She squeezes his hand, wanting to cry. ‘That’s a cool dream, pal, but
        it’s not that simple.’ Her lower back is pinned with titanium screws and
        it’s too early to tell if the bone grafts will really take. Or not. It’s
        the worst place to break a bone; the pivot of every bend. Any twist, any
        jolt or jar, could undo the surgeon’s work.
      </p>
      <p>
        ‘You don’t understand,’ Ted says, his glow now a blush. ‘I know, don’t
        ask me how, but I’m really sure.’
      </p>
      <p>Meg looks to Jack to help her soften the blow.</p>
      <p>
        ‘Why not give it a go?’ he says. ‘He’s been buzzing about it all day.’
      </p>
      <p>‘But it was just a dream.’</p>
      <p>
        Ted squares his shoulders, his mouth a stubborn line. ‘Remember that
        time we stayed with the Ashworth’s when Mum and Dad went to Auckland and
        I nearly fainted and said they’d had a crash — and then it turned out
        they did? What about that?’
      </p>
      <p>
        It was true. Ted was seven, convinced to the point of hysteria, and when
        they were dropped back home, there sat the family car in the driveway,
        its whole rear end stoved-in.
      </p>
      <p>‘Yeah,’ says Jack. ‘That sure was weird.’</p>
      <p>
        ‘See? I knew. Just like now, Meg. I promise. I don’t know how it’ll
        happen, but I know it will. All we have to do is take you there.’ Tears
        gloss his eyes. ‘Please, you have to believe me.’
      </p>
      <p>
        Meg’s eyes water in response. ‘But even if it’s true, how would I get
        there? And what if I slip? Or strain something? It hurts enough just
        getting around here.’
      </p>
      <p>‘We could carry you,’ Ted says. ‘Like on a stretcher or something.’</p>
      <p>
        ‘That’s silly,’ she says. ‘Much too hard. And what if you dropped me?’
      </p>
      <p>
        He jumps to his feet. Looks at her like she’s driven a dagger into his
        heart. ‘Don’t you want to get better?’ He runs from the room.
      </p>
      <p>Meg smears away a tear. ‘He doesn’t understand—’</p>
      <p>
        ‘Maybe he does,’ Jack says. ‘Maybe he can’t bear another day of watching
        you give up on life.’
      </p>
      <p>
        Her mouth hangs open, flailing for words, as he storms off as well. Give
        up? Don’t they understand that the pain may never go away? Everyone
        tells her she’ll have to learn to ‘manage’ it, to ‘pace’ herself. Like
        Uncle Tony every Christmas, who’s subtlely managed to pace his drinks or
        else he picks a fight with every person in the room. Do they think of
        her like that? A troublesome problem to solve?
      </p>
      <p>
        For two days after Ted avoids her and Jack can barely meet her gaze.
        When she ventures from her bed and stumbles across them, all
        conversation stalls.
      </p>
      <p>
        Saturday dawns sunny. Outside, the cabbage trees nod their flaxen dreds.
        Her mother appears at the doorway.
      </p>
      <p>‘Do you feel up to an outing today, love?’</p>
      <p>‘Where to?’ Her brain shouts ‘no’.</p>
      <p>‘A surprise,’ Mum says. She jiggles her brows.</p>
      <p>‘Do I have to?’</p>
      <p>Her mother comes to sit beside her. ‘Please. It will do you good.’</p>
      <p>
        Meg sighs but nods. She has no energy to fight. She carefully swings out
        of bed and pulls on a tee-shirt and jeans. Dad, Ted and Jack are already
        out somewhere, so she’s guessing her surprise is a trawl through the
        mall before a cafe lunch.
      </p>
      <p>
        But when they’re in the car her mother turns onto the expressway,
        heading south.
      </p>
      <p>‘Where are we going?’</p>
      <p>‘You’ll see.’</p>
      <p>
        Within minutes, they take the exit at Mackay’s Crossing to Whareroa
        Farm. Her brothers stand in wait outside the information hut.
      </p>
      <p>
        As they pull up Meg says, ‘You’re kidding, right? This is ridiculous.’
      </p>
      <p>‘Do it for Ted,’ her mother says.</p>
      <p>
        ‘But what if I slip?’ Is Mum mad? She’s the one always nagging to be
        careful.
      </p>
      <p>
        ‘Honey, listen. I know it’s been hard — and what you’ve been through is
        truly scary — but at some point you have to pick up your life again and
        start to move, painful or not.’ She nods towards the boys. ‘This has
        been scary for Ted and Jack as well. They understand the risks and won’t
        let anything happen to hurt you. Believe me, I’ve made that very plain.’
        She pulls a folded piece of notepaper from her handbag and tucks it into
        Meg’s pocket. ‘Read this when you’re there — and just think of this as
        an outing, some sunny Saturday fun. Ted needs to do this for you. Humour
        him.’
      </p>
      <p>
        Meg bites her lips together. She didn’t expect that they’d all gang up
        on her. But what else can she do? She opens the door and slides out
        cautiously. Her mother stays put.
      </p>
      <p>Ted’s smile reaches ear to ear. ‘You ready?’</p>
      <p>‘I guess.’</p>
      <p>
        Her brothers each link an arm through hers to walk beside her. They take
        the Forest Loop Walk, the boys bracing her. Their progress is slow,
        Meg’s gaze locked on the track in front of her, gritting her teeth,
        wondering how she’ll make it all the way up to the hut.
      </p>
      <p>
        Ten minutes in, two pīwakawaka start to flit along in the trees beside
        them, chattering, fanning their tail feathers, bright button eyes fixed
        on them. Ted echoes their chirpy calls, drawing them closer, until one
        darts in front of Meg, hovering there, eyeing her up, before it darts
        back to its mate. Ted laughs, his joy infectious, and Meg blows out a
        deep breath. It’s good to be outside with the smell of the bush and the
        stirring trees.
      </p>
      <p>
        By the time they reach the spot where the Kiwi Caravan’s parked, she’s
        tiring. She leans against the caravan to catch her breath. The hut is
        still an uphill slog through thick grass.
      </p>
      <p>
        ‘That was nice,’ she says. ‘But, honestly, I don’t think I can walk the
        rest. It’s too steep.’
      </p>
      <p>Her brothers exchange grins.</p>
      <p>
        ‘Ah, milady, have no fear,’ says Jack. He holds up his index finger.
        ‘Wait just one moment.’
      </p>
      <p>
        He and Ted disappear behind the caravan and return carrying her new
        kayak. They’ve lined it with pillows and have strung each end with rope.
      </p>
      <p>‘Ta da!’ Ted says. ‘Your carriage awaits!’</p>
      <p>She laughs. ‘You guys are nuts.’</p>
      <p>
        Jack takes a bow. ‘Thank you, dear sister. Now kindly get in and shut
        up!’
      </p>
      <p>
        Meg lowers herself gingerly into the kayak, the pillows cradling her. So
        long as they don’t drag her over rough ground, she should be okay. ‘Are
        you sure I’m not too heavy?’ she says.
      </p>
      <p>
        Ted snorts. ‘You do know you’re all skin and bone now, eh?’ There’s an
        accusation in his voice, as if she’s lost the weight on purpose, not
        simply the result of all the nausea the pain has caused.
      </p>
      <p>She bristles. ‘You try eating when everything hurts.’ </p>
      <p>
        Jack pats Ted as he opens his mouth to bite back. ‘Girls, mate. They
        don’t have the capacity to eat no matter what, like we do!’ He winks at
        Meg. ‘You ready?’
      </p>
      <p>She nods and braces her hands against the side. </p>
      <p>
        Jack lifts the front and starts to tow her, Ted pushing behind. She’s
        lugged uphill, her view restricted to grass and sky. Twice they stop to
        catch their breaths but neither complain, though Ted’s cheeks are pink
        and Jack’s tee-shirt is soaked with sweat. Buttressed in the kayak the
        ride is smoother than she feared.
      </p>
      <p>
        When the roof of the hut comes into view Ted cheers. Its cladding has
        silvered since last summer when it first appeared, but it still looks in
        one piece. He helps Meg to her feet, the view opening up, the sea to the
        west and a stand of bush off to the east, high hills beyond. Ted
        stretches his arms and spins until he falls, dizzy, into the grass,
        while Jack transfers a pillow into the hut and backs Meg in. It’s snug
        inside, her own cocoon.
      </p>
      <p>
        ‘All yours,’ he says. ‘Sit there a while and let Ted’s magic do its
        work!’
      </p>
      <p>
        ‘You do know this is stupid, don’t you?’ she whispers as he turns to
        leave.
      </p>
      <p>‘Dunno. But Ted believes it, so at least give it chance.’</p>
      <p>
        Jack and Ted settle downhill and leave her to her thoughts. She mentally
        scans herself for any new signs of injury, but the pain is no worse than
        usual and the freshness of the air clears cobwebs from her brain.
      </p>
      <p>
        She looks to her left, where the sea blurs into the horizon and Kapiti
        Island lies at anchor, the peak of an ancient mountain range, long
        submerged. This is the landscape she’s always loved and thought of as
        her natural home, yet now her heart starts to thrum and her head pounds.
        Will she ever be able to take to the water again? Even if she heals,
        she’s not sure if she can overcome the fear. It crouches inside her,
        stirring in her gut every time she thinks back to that day. She could
        have died. Nearly did. And she wouldn’t have been the first. In fact,
        since the country’s settlement, many must have died out there.
      </p>
      <p>
        She imagines the first Rangitane and Muaupoko people paddling their waka
        between the island and the coast, trading with their neighbours,
        fetching kai moana, able to read Tangaroa’s shifting moods, paying their
        respects. How many of their whānau didn’t make it home? Or Te
        Rauparaha’s warriors, who delivered so much death and destruction? Were
        there times they, too, were swallowed by an angry Tangaroa? Or the
        whalers and settlers who passed through these waters? Or happy families,
        out for day’s fishing? This stretch of water is the trickster of her
        dreams, one moment a sparkling jewel, the next a raging beast.
      </p>
      <p>
        Memories of that day threaten to swamp her again. Her chest tightens,
        recalling her desperation to draw in air, and the roar of the sea pounds
        in her ears, as it did when she was tossed like driftwood onto the
        rocks.
      </p>
      <p>
        Out of nowhere, she remembers a story her father told her years ago, her
        mind’s eye conjuring up the figure of Kahe Te Rau-o-te-rangi, naked,
        smeared with oil and red ochre, her tiny daughter Ripeka strapped to the
        thick mat of raupo leaves tied around her mother’s shoulders, braving
        the waves as she swam the gulf between island and coast to warn of an
        attack from the south. She must’ve been terrified, not only swimming
        alone all that way through a dark sea, exhausted, cooing to her child to
        calm her, knowing that if the approaching war fleet spied her, they
        would both be lost. But she survived, carried to land by the breaking
        rollers, stiff and weary as she freed Ripeka and revived her, putting
        her to her breast.
      </p>
      <p>
        And Meg, too, has survived. Tangaroa’s giant hand could just have easily
        thrust her down to the sea floor and held her there, not hefted her to
        land.
      </p>
      <p>
        She draws in a shaky breath and turns to the hills, the doorway framing
        the bush beyond. Again, her mind travels back, to a time when a thick
        cloak of trees spilled down to the coast. She imagines the shifts in the
        landscape, sections cleared by Māori for horticulture and pā sites
        before the Mackay of the Crossing stripped the land bare for farming,
        and then the influx of American marines, training, resting and
        recuperating during World War Two before, finally, after years more
        farming, the Whareroa Guardians stepped in and started to replant these
        precious pockets of native bush. Back came the birds. Up shot seedlings
        at long last left to grow. Regeneration, that was the word for it. A
        slow coming back to life and return to its natural state. A healing.
      </p>
      <p>
        She draws more restoring air into her lungs and feels it travel through
        her, exhaling away her hurt and fears. If the land can recover, so can
        she. All it takes is patience. Time. This is the magic Ted has sensed.
      </p>
      <p>
        She remembers her mother’s note and slides it from her pocket, surprised
        to find a poem written there, attributed to someone named Apollinaire.
      </p>
      <p>‘Come to the edge,’ he said.</p>
      <p>‘We can't, we're afraid!’ they responded.</p>
      <p>‘Come to the edge,’ he said.</p>
      <p>‘We can't, We will fall!’ they responded.</p>
      <p>‘Come to the edge,’ he said.</p>
      <p>And so they came.</p>
      <p>And he pushed them.</p>
      <p>And they flew. </p>
      <p>
        Later, once she’s hugged her brothers and whispered ‘You were right’
        into Ted’s ear, she settles back in the fluoro yellow kayak and they
        glide it downhill, cutting a new path through the sea of swaying grass,
        her hands trailing through silky seeds, the breeze a soft kiss on her
        cheeks. It almost feels like flying.
      </p>
      <p>
        <OutboundLink
          href="https://drive.google.com/open?id=1grH4Jskt8hig17vVdw-sSHFfpHlwr4No"
          target="_blank"
          rel="noopener noreferrer"
          className="big-link"
        >
          Download ‘Regeneration’ by Mandy Hager
        </OutboundLink>
      </p>
      <Audio audio={hutAudio} />
    </div>
    <div className="about-the-author u-side-pad">
      <h3 className="heading--small about-the-author__heading">
        About the author
      </h3>
      <p>
        Mandy Hager: Mandy Hager is a multi-award winning author of 11 novels
        and was recently awarded the 2019 Storylines Margaret Mahy Medal for
        life-time achievement and a distinguished contribution to New Zealand’s
        literature for young people. For the past decade she has tutored novel
        writing for Whitireia.
      </p>
    </div>
  </>
)
export default hutFiction
